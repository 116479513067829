import { SearchFilter } from "src/components/Filter/SearchFilter"
import { useUrlPager } from "src/components/Pager/useUrlPager"
import { ParadisePager } from "src/components/Paradise/ParadisePager"
import { ParadiseTable } from "src/components/Paradise/ParadiseTable"
import {
  FilterWrapper,
  ParadiseLayout,
  ParadiseTitle,
  TopWrapper,
} from "src/components/Paradise/sharedStyles"
import { useFetchParadiseProfiles } from "src/data/paradise/paradiseProfiles/queries/paradiseProfileQueries"
import { IParadiseProfile } from "src/data/paradise/paradiseProfiles/types/paradiseProfileQueryTypes"
import { Routes } from "src/router/routes"
import { useRouter } from "src/router/useRouter"
import { useSearchParams } from "src/router/useSearchParams"
import { DropdownMultiSelect } from "src/ui/DropdownSelect/DropdownMultiSelect"
import {
  TableColumn,
  useTableColumns,
} from "src/ui/GridTable/useTableColumns/useTableColumns"
import { Titlebar } from "src/ui/Layout/Titlebar"
import { InternalLink } from "src/ui/Link/InternalLink"
import { MBadge } from "src/ui/MBadge/MBadge"
import { spacing } from "src/ui/spacing"
import { formatUtcDate } from "src/utils/l10n"

const tableColumns: TableColumn<IParadiseProfile>[] = [
  {
    value: "profileId",
    label: "Profile ID",
    disabled: true,
    columnWidth: "auto",
    disableClickPropagation: true,
    renderCell: (data) => (
      <InternalLink to={Routes.ParadiseProfile.location(data.id)}>
        {data.id}
      </InternalLink>
    ),
  },
  {
    value: "name",
    label: "Name",
    disabled: true,
    columnWidth: "auto",
    renderCell: (data) => <div>{data.name}</div>,
  },
  {
    value: "organizationId",
    label: "Organization ID",
    columnWidth: "auto",
    disableClickPropagation: true,
    renderCell: (data) => (
      <InternalLink
        to={Routes.ParadiseOrganization.location(data.organization_id)}
      >
        {data.organization_id}
      </InternalLink>
    ),
  },
  {
    value: "createdAt",
    label: "Created at",
    columnWidth: "auto",
    renderCell: (data) => <div>{formatUtcDate({ date: data.created_at })}</div>,
  },
  {
    value: "lastUpdatedAt",
    label: "Last updated at",
    columnWidth: "auto",
    renderCell: (data) => (
      <div>{formatUtcDate({ date: data.last_modified })}</div>
    ),
  },
]

const LIMIT = 20

export function ParadiseProfiles() {
  const { navigate } = useRouter()

  const { offset, limit, setOffset } = useUrlPager({
    initialLimit: LIMIT,
  })

  const { searchParams: filter, setSearchParams: setFilter } = useSearchParams({
    keys: [
      {
        key: "id",
        type: "string",
      },
      {
        key: "name",
        type: "string",
      },
      {
        key: "organizationId",
        type: "string",
      },
    ],
  })

  const fetchProfiles = useFetchParadiseProfiles({
    filter: {
      offset,
      limit,
      name: filter.name || undefined,
      id: filter.id || undefined,
      organization_id: filter.organizationId || undefined,
    },
    options: {
      keepPreviousData: true,
    },
  })

  function handleFilterChange(filterKey: keyof typeof filter, value: string) {
    setOffset(0)
    setFilter(filterKey, value)
  }

  const {
    headerElements,
    rows,
    templateColumns,
    interactiveColumns,
    interactiveVisibleColumns,
    updateColumnVisibility,
  } = useTableColumns({
    columns: tableColumns,
    data: fetchProfiles.data?.profiles,
    options: { localStorageKey: "minut.profiles.homes.table" },
  })

  return (
    <ParadiseLayout>
      <Titlebar
        bottomMargin={spacing.S}
        title={
          <ParadiseTitle>
            Profiles
            {fetchProfiles.isSuccess && (
              <MBadge color="info">
                Total: {fetchProfiles.data?.paging.total_count}
              </MBadge>
            )}
          </ParadiseTitle>
        }
      />
      <TopWrapper>
        <FilterWrapper>
          <SearchFilter
            initialValue={filter.id ?? undefined}
            onChange={(value) => handleFilterChange("id", value)}
            placeholder="Search by id"
          />
          <SearchFilter
            initialValue={filter.name ?? undefined}
            onChange={(value) => handleFilterChange("name", value)}
            placeholder="Search by name"
          />
          <SearchFilter
            initialValue={filter.organizationId ?? undefined}
            onChange={(value) => handleFilterChange("organizationId", value)}
            placeholder="Search by organization id"
          />
        </FilterWrapper>
        <DropdownMultiSelect
          label="Columns"
          options={interactiveColumns}
          selectedValues={interactiveVisibleColumns.map((c) => c.value)}
          onChange={({ checked, option }) =>
            updateColumnVisibility(option.value, !checked)
          }
        />
      </TopWrapper>
      <ParadiseTable
        header={headerElements}
        rows={rows ?? []}
        templateColumns={templateColumns}
        onRowClick={(index) => {
          if (fetchProfiles.data?.profiles) {
            navigate(
              Routes.ParadiseProfile.location(
                // @ts-expect-error: noUncheckedIndexedAccess
                fetchProfiles.data.profiles[index].id
              )
            )
          }
        }}
      />
      <ParadisePager
        offset={offset}
        limit={limit}
        setOffset={setOffset}
        totalCount={fetchProfiles.data?.paging.total_count}
      />
    </ParadiseLayout>
  )
}
